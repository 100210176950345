<template>
  <section class="timeline">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Timeline</h4>
            <p class="card-description">A simple timeline</p>
            <div class="mt-5">
              <div class="vertical-timeline">
                <div class="timeline-wrapper timeline-wrapper-warning">
                  <div class="timeline-badge"></div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <h6 class="timeline-title">Bootstrap 4 Beta 2</h6>
                    </div>
                    <div class="timeline-body">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pharetra varius quam sit amet vulputate. Quisque mauris augue,</p>
                    </div>
                    <div class="timeline-footer d-flex align-items-center">
                      <i class="icon-heart text-muted mr-1"></i>
                      <span>19</span>
                      <span class="ml-auto font-weight-bold">19 Oct 2017</span>
                    </div>
                  </div>
                </div>
                <div class="timeline-wrapper timeline-inverted timeline-wrapper-danger">
                  <div class="timeline-badge"></div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <h6 class="timeline-title">Bootstrap 4 Beta 1</h6>
                    </div>
                    <div class="timeline-body">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pharetra varius quam sit amet vulputate. Quisque mauris augue,</p>
                    </div>
                    <div class="timeline-footer d-flex align-items-center">
                      <i class="icon-heart text-muted mr-1"></i>
                      <span>25</span>
                      <span class="ml-auto font-weight-bold">10th Aug 2017</span>
                    </div>
                  </div>
                </div>
                <div class="timeline-wrapper timeline-wrapper-success">
                  <div class="timeline-badge"></div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <h6 class="timeline-title">Bootstrap 4 alpha 6</h6>
                    </div>
                    <div class="timeline-body">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pharetra varius quam sit amet vulputate. Quisque mauris augue,</p>
                    </div>
                    <div class="timeline-footer d-flex align-items-center">
                      <i class="icon-heart text-muted mr-1"></i>
                      <span>19</span>
                      <span class="ml-auto font-weight-bold">5th Sep 2016</span>
                    </div>
                  </div>
                </div>
                <div class="timeline-wrapper timeline-inverted timeline-wrapper-info">
                  <div class="timeline-badge"></div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <h6 class="timeline-title">Bootstrap 4 alpha 3</h6>
                    </div>
                    <div class="timeline-body">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pharetra varius quam sit amet vulputate. Quisque mauris augue,</p>
                    </div>
                    <div class="timeline-footer d-flex align-items-center">
                      <i class="icon-heart text-muted mr-1"></i>
                      <span>19</span>
                      <span class="ml-auto font-weight-bold">27th July 2016</span>
                    </div>
                  </div>
                </div>
                <div class="timeline-wrapper timeline-wrapper-primary">
                  <div class="timeline-badge"></div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <h6 class="timeline-title">Bootstrap 3.3.7</h6>
                    </div>
                    <div class="timeline-body">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pharetra varius quam sit amet vulputate. Quisque mauris augue,</p>
                    </div>
                    <div class="timeline-footer d-flex align-items-center">
                      <i class="icon-heart text-muted mr-1"></i>
                      <span>25</span>
                      <span class="ml-auto font-weight-bold">25th July 2016</span>
                    </div>
                  </div>
                </div>
                <div class="timeline-wrapper timeline-inverted timeline-wrapper-info">
                  <div class="timeline-badge"></div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <h6 class="timeline-title">Bootstrap 4 Alpha 1</h6>
                    </div>
                    <div class="timeline-body">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pharetra varius quam sit amet vulputate. Quisque mauris augue,</p>
                    </div>
                    <div class="timeline-footer d-flex align-items-center">
                      <i class="icon-heart text-muted mr-1"></i>
                      <span>32</span>
                      <span class="ml-auto font-weight-bold">19th Aug 2015</span>
                    </div>
                  </div>
                </div>
                <div class="timeline-wrapper timeline-wrapper-success">
                  <div class="timeline-badge"></div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <h6 class="timeline-title">Bootstrap 3.3.5</h6>
                    </div>
                    <div class="timeline-body">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pharetra varius quam sit amet vulputate. Quisque mauris augue,</p>
                    </div>
                    <div class="timeline-footer d-flex align-items-center">
                      <i class="icon-heart text-muted mr-1"></i>
                      <span>26</span>
                      <span class="ml-auto font-weight-bold">15th Jun 2015</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'timeline'
}
</script>